// Smooth scrolling
// import $ from 'jquery';
$('a[href*="#"]')
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function(event) {
        if (
            location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
            &&
            location.hostname == this.hostname
        ) {
            let target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                event.preventDefault();
                $('html, body').animate({
                    scrollTop: target.offset().top
                }, 1000, function() {
                    var $target = $(target);
                    $target.focus();
                    if ($target.is(":focus")) {
                        return false;
                    } else {
                        $target.attr('tabindex','-1');
                        $target.focus();
                    }
                });
            }
        }
    });



// function cycleImages(){
//
//     let $active = $('#background_cycler .active');
//
//     let $next = ($('#background_cycler .active').next().length > 0) ? $('#background_cycler .active').next() : $('#background_cycler div:first');
//
//     $next.css('z-index',2);
//     $active.fadeOut(1500,function(){
//         $active.css('z-index',1).show().removeClass('active');
//         $next.css('z-index',3).addClass('active');
//     });
// }
//
//
// function handleImageCycler() {
//     $('#background_cycler .active').fadeIn(1500);
//     setInterval('cycleImages()', 8000);
// }
//
// $(window).on("load", handleImageCycler);
//
